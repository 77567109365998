<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Essay
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'question_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Essay</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will have 20 minutes to plan, write and revise an essay about the topic. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Writing</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 1 - 2 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>Don’t write under 200 words and should not exceed 300 words. You’ll have the word count at the bottom of the screen. You should avoid repeating the words or sentences.</li>
                <li>Before you write anything, note key words in the prompt. Find out the topic, what key points you should focus on to answer the question and any points of view to present and discuss.</li>
                <li>You will be judged for writing, grammar, vocabulary, spelling, written discourse,</li>
                <li>You also have a choice to cut, copy, or paste the text.</li>
            </ul>
            <p><strong>Cut:</strong> Select text from your answer that you wish to remove and left-click “Cut”.</p>
            <p><strong>Copy:</strong> Select text from your answer that you wish to copy and left-click “Copy”.</p>
            <p><strong>Paste:</strong> Place the cursor where you wish to paste the cut/copied text and left-click “Paste”.</p>
            </template>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(selectedquestion.question_id != 0)}" v-if="selectedquestion">
            <b-overlay class="fixedHeight" :show="show" rounded="sm" >
             <h4>Write Essay</h4>
            <p class="questHeading">You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.</p>
            <div class="prepare" ><p>Remain </p><app-Timer ref="timer"/></div>
            <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <h4 class="mt-2 mb-2">Topic</h4>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblEssayQuestion'"/>
            </div>
            </div>
            <p class="questdesc mb-4">{{selectedquestion.question_desc}}</p>
            <textarea id='text' v-model="inputText" @keydown="recheckInput" data-gramm="false" spellcheck="false"></textarea>
            <p style="padding-top: 5px !important;padding-left: 5px !important;"><strong>Word Count: {{wordCount}}</strong></p>
            <!-- <div class="row extraMargin">
                <div class="col-12" v-if="suggestions != null">
                        <ul v-for="item in suggestions" :key="item.answer_id">
                            <li>Sentence: <strong>{{item.sentence}}</strong></li>
                            <li  class="suggestion">Mistake Description: {{item.message}}</li>
                            <li class="suggestion">Replacement Suggestions:
                                <template v-for="(result,index) in item.replacements">
                                    <span :key="index"> {{result.value}} , </span>
                                </template>
                                </li>
                        </ul>
                        <div class="mt-10" v-if="suggestions.length ==0">
                            <h5>No Mistake Found</h5>
                        </div>
                        <div class="divTable blueTable mt-10" v-if="suggestions">
                            <div class="divTableBody">
                                <div class="divTableRow">
                                    <div class="divTableCell ">Content</div>
                                    <div class="divTableCell">{{this.score.content}}</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Form</div>
                                    <div class="divTableCell">{{this.score.form}}</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Grammar</div>
                                    <div class="divTableCell">{{this.score.grammar}}</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Spellings</div>
                                    <div class="divTableCell">{{this.score.spelling}}</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Vocabulary</div>
                                    <div class="divTableCell">{{this.score.vocab}}</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">Development, structure and coherence</div>
                                    <div class="divTableCell">{{this.score.structure}}</div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">General Linguistic Range</div>
                                    <div class="divTableCell">{{this.score.linguistic}}</div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="col-12 displayInline mb-5" align="center" v-if="selectedquestion">
                <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                <b-button variant="success" @click="submitanswer()">Check Score And Suggetions</b-button>
                <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
            </div> -->
            </b-overlay>
            <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0">
                    <b-button :disabled="inputText == ''" class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <!-- <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button> -->
                    <b-button :disabled="apiResults ==null" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'en-US', unchecked: 'en-GB'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <b-modal  ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
            <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <b-modal id="result-Modal" size="lg" centered hide-footer title="AI Result">
         <b-container class="resultModel" >
               <app-WritingResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
       </b-modal>
       <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserEssayAnswer'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import IntervalList from '../reuseable/intervalList'
import $ from 'jquery'
//import LocalStorageService from '../../store/LocalStorageService'
import unirest from "unirest";
import WritingResultPresent from '../reuseable/writeResultPresent'
import QuestionStatus from '../reuseable/questStatus'
import DiscussionTab from '../reuseable/discussionTab'
// var translator = require('american-british-english-translator') //used for american and british spellings.
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-WritingResultPresent':WritingResultPresent,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            show:false,
            buttonText:'Submit Answer',
            selectedquestion: null,
            inputText:'',
            wordCount:0,
            limitCharter: 350,
            limitDone:false,
            charCount:0,
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false,
            userAnswerResponse:null,
            suggestions:null,
            user_type:'Free',
            userAnswer:{
                question_id:0,
                user_id:0,
                essay_desc:'',
                url:'',
                api_result:null,
                mock_test_id:0,
                user_result:''
            },
            score:{
                content: 3,
                form: 2,
                spelling:2,
                grammer:2,
                vocab: 2,
                structure:2,
            },
            apiResults:null,
            resultStatus:'AI-Result',
            isShowModal: false,
            modelTitle:'heading',
            aud:null,
            searchKey:'',
            searchQuestionList:null,
             currentUser:null,
             checkAnswerFlag:false,
            AIWritingData:{
                analysisType:"",
                langType:"",
                questType:"",
                userAnswer:"",
                question:"",
                url:"AIWritting/AIMarkingResult",
                question_Id:0
            }
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'writtingmodule/fetchQuestion',
            saveAnswer: 'writtingmodule/saveAnswer',
            fetchUserUsage:'auth/saveDashBoardData',
        }),
        ...mapGetters({
            getQuestionList: 'writtingmodule/questions',
            answerResponse: 'writtingmodule/AnswerResponse',
            GetUserUsageResponse:'auth/getDashBoardResponse'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        redoQuest(){
            this.getQuestionDetail(this.selectedquestion.question_id)
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            //console.log(this.selectedquestion)
            //console.log(question)
            //console.log(id)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            //console.log(currectQuestionIndex)
            //console.log(this.questionLists[currectQuestionIndex])
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            // this.questionLists[currectQuestionIndex].predicted_ind=this.selectedquestion.predicted_ind
            // this.questionLists[currectQuestionIndex].predicted_date=this.selectedquestion.predicted_date
            // this.questionLists[currectQuestionIndex].exam_ind=this.selectedquestion.exam_ind
            // this.questionLists[currectQuestionIndex].exam_date=this.selectedquestion.exam_date
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.resetAll()
            this.userAnswer.questuion_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            //////console.log(this.selectedquestion.written_text)
            this.startFlag = false
            this.$nextTick(function(){
                this.$refs.discussionTab.updateQuestionId(questionId)
                this.$refs.timer.setTime(1200)
            })
        },
        recheckInput:function(evt){
            if(evt.keyCode == 32){
            if(this.limitCharter == this.wordCount)
            {
                this.limitDone = true;
                    this.charCount = this.inputText.length	
            }
            }
                if(this.limitDone & evt.keyCode !=32){
                    this.limitDone = false;
            }
        },
        timerEnd(){
            //alert('Time Finish')
        },
        userUsage(){
        var data={
            user_id: this.currentUser.user_id,
            url:'users/userUsageCount'
          }
          this.fetchUserUsage(data).then(()=>{
            ////console.log(this.GetUserUsageResponse())
           this.currentUser.writing_Counter= this.GetUserUsageResponse().data.writingCount
            //this.$ls.remove('PackageType')
          })
        },
        checkUserAccess(){
            const user =this.currentUser
            if(user.user_type == 'Free' & user.writing_Counter>=5)
            {
                return false
            }
            else if(user.user_type == 'standard' & user.writing_Counter>=10)
            {
                return false
            }
            else{
                this.currentUser.writing_Counter++
                return true
            }
        },
        submitanswer(){
            try{
                    if(!this.checkUserAccess())
                        {
                            return this.$alert(
                                "Please upgrade your plan for further practice your today limit is over",
                                "Warning",
                                "warning",
                                {
                                    confirmButtonText: "Cannot Proceed"
                                })
                        }
                if(this.inputText.length<10){
                    return this.$alert('No answer found, Against this question.')
                    }
                    this.resultStatus='In-Process'
                    this.show=true
                    var userdata =this.$ls.get('user')
                    this.userAnswer.user_id = userdata.user_id
                    let lang="en_UK";
                    lang=(this.checkAnswerFlag)? "en-US":"en-GB";
                    lang=(lang=="en-GB")?"en_UK":"en_US";
                    this.AIWritingData.langType=lang;
                    this.AIWritingData.analysisType="Academic"
                    this.AIWritingData.questType="essay"
                    this.AIWritingData.userAnswer=this.inputText
                    this.AIWritingData.question=this.selectedquestion.question_desc
                    this.AIWritingData.question_Id=this.selectedquestion.question_id
                    var vm = this
                   
                    this.saveAnswer(this.AIWritingData).then(()=>{
                        let AIResponse= this.answerResponse();
                        //console.log(AIResponse);
                        vm.userAnswer.mock_test_id=0
                        vm.userAnswer.essay_desc = vm.inputText;
                        vm.userAnswer.url = 'useressayanswer/createuseressay'
                        vm.resultStatus='AI-Result Ready'
                        vm.show=false
                         vm.apiResults=AIResponse.apiResults;
                        vm.userAnswer.api_result=JSON.stringify(vm.apiResults)
                        var writtenDiscourse=2
                        if(vm.apiResults.content>=2)
                        {
                            writtenDiscourse=2
                        }
                        else if(this.apiResults.content>=1){
                            writtenDiscourse=this.apiResults.content-0.15
                        }
                        else{
                            writtenDiscourse=1
                        }
                        var overAllScore =(vm.apiResults.content+vm.apiResults.form+vm.apiResults.spelling
                                            +vm.apiResults.grammer+vm.apiResults.structure+vm.apiResults.vocab+writtenDiscourse)
                        vm.userAnswer.user_result='Your Score: '+overAllScore+'/15' 
                        vm.userAnswer.question_id=vm.currentQuestion
                        //console.log(vm.userAnswer)
                        vm.saveAnswer(vm.userAnswer).then(()=>{
                        vm.userAnswerResponse = vm.answerResponse().data
                        });
                    });

            }
            catch(error){
                this.show=false
            }
        },
        submitanswerOld() {
            try
            {
        if(!this.checkUserAccess())
        {
            return this.$alert(
                "Please upgrade your plan for further practice your today limit is over",
                "Warning",
                "warning",
                {
                    confirmButtonText: "Cannot Proceed"
                })
        }
        this.resultStatus='In-Process'
        this.show=true
        var userdata =this.$ls.get('user')
        this.userAnswer.user_id = userdata.user_id
        const lang=(this.checkAnswerFlag)? "en-US":"en-GB";
        const  req = unirest("POST", "https://grammarbot.p.rapidapi.com/check");
            req.headers({
                "content-type": "application/x-www-form-urlencoded",
                "x-rapidapi-key": process.env.VUE_APP_GRAMMER_API_KEY,
                "x-rapidapi-host": "grammarbot.p.rapidapi.com",
                "useQueryString": true
            });

            req.form({
                "text": this.inputText,
                "language": lang//"en-US"
            });
            var vm = this
            this.userAnswer.api_result=[]
            req.end(function (res) {
                if (res.error) throw new Error(res.error);
                //console.log(res.body)
                vm.suggestions = res.body.matches;
                vm.checkScore()
                vm.userAnswer.essay_desc = vm.inputText;
                vm.suggestions=(vm.suggestions.length<=0)?'No spelling and grammar error':vm.suggestions
                // if(vm.suggestions.length>1){
                //     vm.suggestions.forEach(element => {
                //         vm.userAnswer.api_result.concat(element)
                //     });
                // }
                // else{
                    vm.userAnswer.api_result = JSON.stringify(vm.suggestions)
                // }
                vm.userAnswer.mock_test_id=0
                vm.userAnswer.url = 'useressayanswer/createuseressay'
                //console.log(vm.userAnswer.essay_desc)
                vm.resultStatus='AI-Result Ready'
                vm.show=false
                var writtenDiscourse=2
                if(vm.apiResults.content>=2)
                {
                    writtenDiscourse=2
                }
                else if(this.apiResults.content>=1){
                    writtenDiscourse=this.apiResults.content-0.15
                }
                else{
                    writtenDiscourse=1
                }
                var overAllScore =(vm.apiResults.content+vm.apiResults.form+vm.apiResults.spelling
                    +vm.apiResults.grammer+vm.apiResults.structure+vm.apiResults.vocab+writtenDiscourse)
                vm.userAnswer.user_result='Your Score: '+overAllScore+'/15' 
                vm.userAnswer.question_id=vm.currentQuestion
                //console.log(vm.userAnswer)
                
                
                vm.saveAnswer(vm.userAnswer).then(()=>{
                vm.userAnswerResponse = vm.answerResponse().data
                });
            })
            }
            catch(error){
                this.show=false
            }
        },
        resetAll() {
            this.inputText = ''
            this.userAnswer ={
                question_id:0,
                essay_desc:'',
                url:'',
                api_result:null,
                user_id:this.$ls.get('user').user_id,
                mock_test_id:0,
                user_result:''
            }
            this.suggestions = null

            this.score = {
                    content: 3,
                    form: 2,
                    spelling:2,
                    grammer:2,
                    vocab: 2,
                    structure:2,
            }
            this.apiResults=null
            this.resultStatus='AI-Result'
            this.show=false
            this.isShowModal= false
            this.modelTitle='heading'
            this.aud=null
            this.searchKey=''
            this.searchQuestionList=null
            this.AIWritingData={
                analysisType:"",
                langType:"",
                questType:"",
                userAnswer:"",
                question:"",
                url:"AIWritting/AIMarkingResult"
            }
        },
        findDuplicateWords(str){
            const strArr = str.split(" ");
            const res = [];
            const commonWords=['is', 'am', 'are', 'was', 'were', 'they', 'the', 'their', 'there', 'which','it','its', 'a', 'has', 'have', 'had','when','how','who']
            for(let i = 0; i < strArr.length; i++){
                const element=strArr[i].replace(',','').replace('.','')
                if(strArr.indexOf(element) !== strArr.lastIndexOf(element)){
                    if(!res.includes(element)){
                        if(!commonWords.includes(element))
                        {res.push(element)}
                    }
                }
            }
            return res.join(" ")
        },
        checkScore(){
            this.score={
                    content: 0,
                    form: 2,
                    spelling:2,
                    grammer:2,
                    vocab: 0,
                    structure:0,

                }
            if(this.inputText.length<=0){
                return this.score={
                    content: 0,
                    form: 0,
                    spelling:0,
                    grammer:0,
                    vocab: 0,
                    structure:0,

                }
            }
            var qtext=this.selectedquestion.question_desc.toLowerCase()
            var questionText=qtext.split(' ')

            var checkFullStopInLast = this.inputText.trim().slice(-1);
            var fullContent=this.inputText

            // var pronounList=['i','we','you','he','she','it','they','me','us','her','him','it','them','mine'
            // ,'ours','yours','her','his','theirs','my','our','your','myself','yourself','herself','itself',
            // 'ourself','yourself','themself'];

            var conjunctionWords=['therefore','however','moreover','thus','meanwhile','thereafter','indeed',
            'instead','consequently','eventually','finally','still','besides','because',
            'since','so that','in order','though','although','even though','while','but',
            'yet','or','so','nor','after','as long as','as soon as','before','by the time',
            'now that','once','since','till','until','when','whenever','while'];

            var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
            , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
            ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
            ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
            ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
            ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
            'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
            ,'as has been noted','for the most part','As discussed above']

            // var contrastWords=['but', 'however', 'in spite of', 'on the one hand', 'on the other hand', 'nevertheless',
            //  'nonetheless', 'notwithstanding', 'in contrast', 'on the contrary', 'still', 'yet']

            //  var exampleWords=['for example', 'for instance', 'such as','like','example','also', 'in the same way', 'just as', 'so too', 'likewise', 'similarly'
            //   ,'namely', 'specifically', 'to illustrate']
            // Content Scoring
            
            var inputArray=fullContent.toLowerCase().split(' ');
            var matchWordsCount=0
            var conjCount=0
            //var exampleCount=0
            //var contrastCount=0
            var conclusionCound=0
            for(var i=0;i<questionText.length;i++){
                questionText[i]=questionText[i].trim();
            }
            // questionText = $.map(questionText, function(value){
            //     return value.trim();
            //     });
            const commonWords=['is', 'am', 'are', 'was', 'were', 'they', 'the', 'their', 'there', 'which','it','its', 'a', 'has', 'have', 'had','when','how','who']
            for (var i=0; i< inputArray.length;i++){
                const element=inputArray[i].replace(',','').replace('.','').toLowerCase()
                if(questionText.indexOf(element)>-1 & commonWords.indexOf(element)<0) 
                {
                    
                    matchWordsCount++
                }
                if(conjunctionWords.indexOf(element)>-1) conjCount++
                //if(exampleWords.indexOf(element)>-1) exampleCount++
                //if(contrastWords.indexOf(element)>-1) contrastCount++
            }
            for (let index = 0; index < conculsionWords.length; index++) {
                const element = conculsionWords[index];
                if(fullContent.search(element)>-1) conclusionCound++ 
            }
            //fullContent.search()
            
            if(matchWordsCount >= questionText.length*.5) this.score.content = 3
            else if(matchWordsCount >= questionText.length*.4) this.score.content = 2
            else if(matchWordsCount >= questionText.length*.3) this.score.content = 1
            else if(matchWordsCount >= questionText.length*.2) this.score.content = .5
            else this.score.content = 0

            
            if(conjCount >= 2) this.score.structure = 1
            else if(conjCount >= 1) this.score.structure = 1
            else this.score.structure = 0

            if(conclusionCound >0) this.score.structure += 0.5
            if(checkFullStopInLast == '.') this.score.structure += 0.5
           

            // Form scoring. also effect content
            var contentWordCount=inputArray.length
            //console.log(contentWordCount)
            if(contentWordCount >= 200  && contentWordCount <= 300)
            {
                this.score.form=2
            }
            else if(contentWordCount > 120 && contentWordCount <= 199) 
            {
                this.score.form=1
            }
            else if(contentWordCount > 300 && contentWordCount <= 380) 
            {
                this.score.form=1
            }
            else {
                this.score.form=0
            }


            var transcript= this.inputText
            var grammerMistakesCount=0
            var spellingMistakeCount=0
            if(this.suggestions!="No spelling and grammmar error")
            {
               
            for (let index = this.suggestions.length-1; index >=0; index--) {
                const element = this.suggestions[index]
                if(this.suggestions[index].rule.issueType !='misspelling') 
                {
                    grammerMistakesCount++
                }
                else 
                {
                    // ////debugger
                    // //console.log(JSON.stringify(translator.translate('favor', options)));
                    // const resu=JSON.stringify(translator.translate('favor', options))
                    // //console.log(resu)
                    spellingMistakeCount++
                }
                const offset= element.offset
                const length= element.length
                let word= transcript.substring(offset,offset+length)
                //it was used for american and britsih spellings
                // if(this.suggestions[index].rule.issueType =='misspelling')
                // {
                //     ////debugger
                //     if(word!=null)
                //     {
                //         if(word!=''){
                    //  var options = {
                    // spelling: true,
                    // american: true
                    // }
                //             var checkAmerican=translator.translate(word, options);
                //             if(checkAmerican[1]!=undefined)
                //             {
                //                 word='';
                //                 spellingMistakeCount--
                //                 //console.log(checkAmerican)
                //             }
                //         }
                //     }
                // }
                ///if(word !='')
                transcript = transcript.substring(0, offset) + '<span id="'+index+'" class="text-danger" style="background:yellow;">'+word+'</span>' + transcript.substring(offset+length);
            }
            }
            
            this.score.grammer=this.score.grammer-(grammerMistakesCount * 0.25);
            if(this.score.grammer<0)this.score.grammer=0

            this.score.spelling=this.score.spelling-(spellingMistakeCount * 1);
            if(this.score.spelling<0)this.score.spelling=0

            //Vacob marking
            const duplicateWords=this.findDuplicateWords(fullContent)
            var countDuplicate=0
            if(duplicateWords!=null) if(duplicateWords.length>0) countDuplicate=duplicateWords.split(' ').length+1;
            //debugger
            if(countDuplicate < 40)this.score.vocab = 2
            else if(countDuplicate < 45) this.score.vocab = 1.5
            else if(countDuplicate < 48) this.score.vocab = 1
            else if(countDuplicate < 50) this.score.vocab = .5
            else this.score.vocab = 0 


            //if(matchWordsCount < 3 ) this.score.content =0

            this.apiResults={
                    content: this.score.content,
                    form: this.score.form,
                    spelling:this.score.spelling,
                    grammer:this.score.grammer,
                    vocab: this.score.vocab,
                    structure: this.score.structure,
                    transcript:transcript,
                    text:this.inputText,
                    quest:'essay',
                    maxMarks:15,
                    contentMax:3
                }
            //console.log(transcript)
            //console.log(this.score)
            //console.log(this.apiResults)

        }
    },
    
    beforeMount(){
        this.questionservice('configessay/getallessays').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            //console.log()
            this.user_type = userdata.user_type
            this.currentUser=userdata
            this.userUsage()
            ////console.log(this.questionLists)
        })
    },
    watch: {
        inputText: function (val) {
        if(this.limitDone & this.charCount <= val.length) {
        this.inputText = this.inputText.substr(0, this.charCount)
        }
        if(this.charCount > val.length){
        this.limitDone = false;
        }
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    }
}
</script>
<style>
#answerTrans span{
    background: yellow!important;
    color:red!important;
}
#answerTrans span.possibleMistake{
    background: #f33176!important;
    color: #fff!important;

}
.noteDiv{
    text-align: right;
    width: 100%;
    font-weight: 700;
    position: absolute;
    top: -30px;
    right: 10px;
}

</style>
<style scoped>
textarea {
    height: 75vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
.suggestion{
    color: red;
}
div.blueTable {
  background-color: #EEEEEE;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.divTableCell, .divTableHead {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
.divTableBody .divTableCell {
  font-size: 14px;
}
.extraMargin
{
    margin-top: 25px!important;
}


/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableBody { display: table-row-group;}
</style>